.registerWrapper {
    text-align: center;
}

.registerLogoWrapper img {
    width: 10%;
}

.registerLogoWrapper p {
    color: #fff;
}

.registerInputWrapper {
    color: #fff;
}

.registerInputWrapper input {
    display: block;
    margin: auto;
    background: none;
    border: 1px solid #fff;
    width: 25%;
    height: 4vh;
}