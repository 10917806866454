.certificationContainer {
    background-image: url('../bgimg/certiBg.jpg');
    width: 100%;
    height: 100vh;
    opacity: 0;
    animation: fadeInBackground 1s ease-in-out forwards;
    overflow-y: hidden;
    background-size: cover;
}

.certiTextWrapper {
    width: 46%;
    height: 100vh;
    border-right: 1px solid #fff;
    margin-left: 4%;
}

.certiTextWrapper p:nth-child(1) {
    color: #dfdfdf;
    font-size: 1.2rem;
    animation: slideUp 1s ease-in-out;
    margin-top: 30%;
}

.certiTextWrapper p:nth-child(2) {
    color: #fff;
    font-size: 2.5rem;
    margin-top: 1%;
    font-weight: bold;
    animation: slideRight 2s ease-in-out;
}

.certiTextWrapper p:nth-child(3) {
    color: #c7c7c7;
    font-size: 1.1rem;
    line-height: 27px;
    animation: slideUp 1s ease-in-out;
}

.certificationWrapper {
    display: flex;
}

.certificationWrapper a:nth-child(1) {
    margin-top: 35%;
}

.certificationWrapper a {
    color: #fff;
    border: 1px solid #fff;
    margin-top: 10%;
    height: 8vh;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px 0 15px 0;
    transition: 0.3s;
}

.certificationWrapper a:hover {
    background-color: #fff;
    color: #000;
}

.certiListWrapper1 {
    width: 19%;
    text-align: center;
    margin-left: 4%;
    margin-right: 2%;
    animation: slideUp 1s ease-in-out;
}

.certiListWrapper2 {
    width: 19%;
    text-align: center;
    margin-right: 4%;
    margin-left: 2%;
    animation: slideDown 2s ease-in-out;
}

.certiList1 {
    display: flex;
    flex-direction: column;
}

.certiList2 {
    display: flex;
    flex-direction: column;
}