.headerWrapper {
    display: flex;
    justify-content: space-between;
    padding-top: 2%;
    padding-bottom: 2%;
    align-items: center;
    color: #fff;
    width: 100%;
    height: 100%;
}

.logoWrapper {
    width: 100%;
    text-align: center;
}

.logoWrapper img {
    width: 35%;
}

.logoWrapper img:hover {
    cursor: pointer;
}

.navWrapper {
    font-size: 1.2rem;
    width: 100%;
    text-align: center;
}

.loginHeaderWrapper {
    font-size: 1.2rem;
    width: 100%;
    text-align: center;
}

.navWrapper span {
    margin: 20px;
    transition: 0.4s;
}

.navWrapper span:hover {
    color: #c7c7c7;
    cursor: pointer;
}

.loginHeaderWrapper span {
    margin: 20px;
    transition: 0.4s;
}

.loginHeaderWrapper span:hover {
    color: #c7c7c7;
    cursor: pointer;
}

.contentHeader {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
  }
/* 하이텍 */
  .navWrapper a {
    color: #fff;
  }